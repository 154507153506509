import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { LeaveButton } from '../Components/Button.js';
import CardPopup from '../Components/CardPopup';
import { BackgroundStyle, GameTextStyle } from '../Components/GeneralStyles.js';
import GameHeader from '../Components/Header.js';
import LoadingScreen from '../Components/LoadingScreen';
import PersonCard from '../Components/PersonCard';
import { ApiRequest, CachedPersonsData, GameScore, PersonsData } from '../Controllers/ApiRequest';

const cardStyle = {
  marginTop: '10px',
  display: 'grid',
  gap: '10px',
  gridTemplateColumns: 'repeat(8, 1fr)',
  justifyContent: 'center',
  width: '50%',
  margin: '0 auto',
};

const GuessWhoGame = ({ gameId, playerName, body }) => {
  const [personsData, setPersonsData] = useState([]);
  const [opponentPersonsData, setOpponentPersonsData] = useState([]);
  const [cardsState, setCardsState] = useState([]);
  const [playerWon, setPlayerWon] = useState(false);
  const [playerDone, setPlayerDone] = useState(false);
  const [adminWon, setAdminWon] = useState(false);
  const [adminDone, setAdminDone] = useState(false);
  const [greenIndex, setGreenIndex] = useState(0);
  const [gameScore, setGameScore] = useState(null);
  const [showPopup, setShowPopup] = useState(true);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isBigScreen = useMediaQuery({ query: '(min-width: 2000px)' });

  const admin = playerName === "Player 1";

  let stompClient = null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ApiRequest(CachedPersonsData, gameId)
        setPersonsData(data.persons);
      } catch (error) {
        fetchData()
      }
    };

    if (admin) {
      ApiRequest(PersonsData, body)
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (personsData.length > 0) {
      const initialCards = personsData.map((_, index) => (index === greenIndex ? 'green' : 'red'));
      setCardsState(initialCards);
    }
  }, [personsData, greenIndex]);

  useEffect(() => {
    if (personsData && personsData.length > 0) {
      setGreenIndex(Math.floor(Math.random() * personsData.length));
    }
  }, [personsData.length]);

  useEffect(async () => {
    try {
      const data = await ApiRequest(GameScore, gameId);
      setGameScore(data);
    } catch (error) {
      console.error('Error fetching game score data:', error);
    }
  }, [gameScore]);

  useEffect(() => {
    stompClient = Stomp.over(new SockJS('/game'));

    stompClient.connect({}, async () => {
      const gameState = {
        gameId: gameId,
        playerName: playerName,
        opponentCardState: cardsState,
        joinerWon: playerWon,
        joinerDone: playerDone,
        adminWon: adminWon,
        adminDone: adminDone
      };

      setPlayerWon(false)
      setPlayerDone(false)
      setAdminWon(false)
      setAdminDone(false)

      if (admin) {
        stompClient.send('/app/game/admin', {}, JSON.stringify(gameState));
        stompClient.subscribe('/topic/game/joiner', (message) => {
          const opponentState = JSON.parse(message.body);

          if (opponentState.joinerWon) {
            setPersonsData([]);
          }
          if (opponentState.joinerDone) {
            window.location.reload();
          }

          setOpponentPersonsData(opponentState);
        });
      } else {
        stompClient.send('/app/game/joiner', {}, JSON.stringify(gameState));
        stompClient.subscribe('/topic/game/admin', (message) => {
          const opponentState = JSON.parse(message.body);

          if (opponentState.adminWon) {
            setPersonsData([]);
          }
          if (opponentState.adminDone) {
            window.location.reload();
          }

          setOpponentPersonsData(opponentState);
        });
      }
    }, (error) => {
      console.error('WebSocket connection error:', error);
      window.location.reload();
    });

    return () => {
      if (stompClient == null) {
        stompClient.disconnect();
        console.log('Disconnected from WebSocket');
      }
    };
  }, [gameId, playerName, cardsState, personsData]);

  const handleClick = async (index) => {
    const newCardsState = [...cardsState];
    if (index === greenIndex) {
      if (admin) {
        setAdminWon(true);
      } else {
        setPlayerWon(true);
      }
      setPersonsData([]);

      const data = await ApiRequest(PersonsData, body);
      setShowPopup(true)
      setPersonsData(data.persons);

      if (admin) {
        setAdminDone(true);
      } else {
        setPlayerDone(true);
      }
    } else {
      newCardsState[index] = 'grey';
      setCardsState(newCardsState);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const gameTextStyle = (textAlign) => {
    return GameTextStyle(textAlign, isTabletOrMobile, isBigScreen)
  }

  return (
    <div style={BackgroundStyle}>
      <GameHeader gameId={gameId} gameScore={gameScore} />

      <div className="player-card-container" style={cardStyle}>
        {
          personsData.length === 0 ? (
            <LoadingScreen />
          ) : (
            personsData.map((person, index) => (
              <PersonCard
                key={index}
                person={person}
                cardState={index !== greenIndex ? cardsState[index] : 'green'}
                handleClick={() => handleClick(index)}
                admin={admin}
                hideCard={false}
              />
            ))
          )
        }
      </div>

      <div className="opponent">
        <p style={{ ...gameTextStyle('left'), marginLeft: "40px" }}> Motståndaren {opponentPersonsData.playerName} </p>
        <div className="opponent-card-container" style={cardStyle}>
          {opponentPersonsData.opponentCardState && personsData.map((person, index) => (
            <PersonCard
              key={index}
              person={person}
              cardState={
                opponentPersonsData.opponentCardState[index] === 'green' ? 'red' : opponentPersonsData.opponentCardState[index]
              }
              admin={!admin}
            />
          ))}
        </div>
      </div>

      {personsData[greenIndex] &&
        <CardPopup
          open={showPopup}
          onClose={closePopup}
          personsData={personsData[greenIndex]}
          officeFilteredGame={true}
          isTabletOrMobile={isTabletOrMobile}
          isBigScreen={isBigScreen}
        />
      }

      <LeaveButton isTabletOrMobile={isTabletOrMobile} isBigScreen={isBigScreen} />
    </div>
  );
};


export default GuessWhoGame;
