export const GameScore = 'game/score'
export const PersonsData = 'persons'
export const NamesData = 'persons/names'
export const CachedPersonsData = 'persons/game'
export const RefreshGame = 'persons/clear-game'
export const GameStart = 'game/start'
export const GameEnd = 'game/end'

export async function ApiRequest(url, body) {
    const maxRetries = 5;
    let retries = 0;

    while (retries < maxRetries) {
        try {
            const response = await fetch("/api/" + url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (response.ok) {
                try {
                    const data = await response.json();
                    return data;
                } catch (jsonError) {
                    retries++;
                }
            }
        } catch (error) {
            throw new Error(`Error: ${error.message}`);
        }
    }

    throw new Error('Maximum retries exceeded');
}

export default {
    GameScore,
    PersonsData,
    CachedPersonsData,
    RefreshGame,
    GameStart,
    GameEnd,
    ApiRequest,
};
